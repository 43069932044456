<template>
    <form @submit="isFormLoading = true" class="form" :class="{ 'form--quick' : quickForm }" method="post" :action="'https://aarongunn.ca/webservices/' + action">
        <slot></slot>
        <div class="form__item form__item--stacked">
            <label v-if="!quickForm">First Name*</label>
            <input name="firstname" type="text" autocomplete="given-name" placeholder="First Name" required/>
        </div>
        <div class="form__item form__item--stacked">
            <label v-if="!quickForm">Last Name*</label>
            <input name="lastname" type="text" autocomplete="family-name" placeholder="Last Name" required/>
        </div>
        <div class="form__item">
            <label v-if="!quickForm">Email*</label>
            <input name="email" type="email" autocomplete="email" placeholder="Email" required/>
        </div>
        <div class="form__item" v-if="showAddress">
            <label v-if="!quickForm">Address*</label>
            <input name="address" type="text" autocomplete="address-line1" placeholder="Address" required/>
        </div>
        <div class="form__item form__item--stacked">
            <label v-if="!quickForm">Postal Code*</label>
            <input name="postalcode" type="text" autocomplete="postal-code" placeholder="Postal Code" pattern="[A-Za-z][0-9][A-Za-z][\s]?[0-9][A-Za-z][0-9][\s]?$" required/>
        </div>
        <div class="form__item form__item--stacked">
            <label v-if="!quickForm">Phone*</label>
            <input name="phone" type="tel" autocomplete="tel" placeholder="Phone Number" required/>
            <!-- pattern="[1]?[-\s]?[\(]?\d{3}[\)]?[-\s]?\d{3}[-\s]?\d{4}" -->
        </div>
        <div class="form__item" v-if="showBestTimeToCall">
            <label v-if="!quickForm">Best Time to Call*</label>
            <input name="schedule_call_best_time" type="text" autocomplete="off" placeholder="Best Time To Call" required/>
        </div>
        <div class="form__item" v-if="showMessage">
            <label v-if="!quickForm">Message</label>
            <textarea name="message" rows="10"></textarea>
        </div>
        <div class="form__item" v-if="showCheckboxes">
            <label class="form__checkbox"><input type="checkbox" name="potential_doner" value="yes"/>I’m interested in contributing financially</label>
            <label class="form__checkbox"><input type="checkbox" name="potential_volunteer" value="yes" />I’m interested in becoming a volunteer</label>
            <label class="form__checkbox"><input type="checkbox" name="potential_friends_supporters" value="yes" />I have friends and family who would be interested in supporting Aaron too</label>
        </div>

        <input type="hidden" name="source" :value="source"/>
        <input type="hidden" name="destination" :value="destination"/>
        <input v-if="getCampaign" type="hidden" name="referral_campaign" :value="getCampaign"/>

        <input type="hidden" ref="recaptcha" name="g-recaptcha-response"/>

        <input type="hidden" v-for="hiddenParameter in hiddenParameters" :name="hiddenParameter.name" :value="hiddenParameter.value"/>

        <button type="submit" :disabled="isFormLoading" class="button button--red button--large">
            <template v-if="!isFormLoading">{{ submitText }}</template>
            <div class="loading" v-else>
                Submitting... 
                <svg class="loading__spinner" viewBox="0 0 50 50"><circle class="loading__path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
            </div>
        </button>
    </form>
</template>

<script>
export default {
    props: {
        source: String,
        action: {
            type: String,
            default: "forms.php"
        },
        hiddenParameters: {
            type: Array,
            default() {
                return []
            }
        },
        showMessage:  {
            type: Boolean,
            default: false
        },
        showCheckboxes: {
            type: Boolean,
            default: false
        },
        showAddress: {
            type: Boolean,
            default: false
        },
        showBestTimeToCall: {
            type: Boolean,
            default: false
        },
        quickForm:  {
            type: Boolean,
            default: false
        },
        submitText: {
            type: String,
            default: "Submit"
        },
        destination: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isFormLoading: false
        }
    },
    mounted () {
        grecaptcha.ready(() => {
            grecaptcha.execute('6Lficu8oAAAAAC9UhsXNFMb_dpI6eOvvhr-F3SWn', {action:'validate_captcha'}).then((token) => { 
                this.$refs["recaptcha"].value = token;
            });
        });
    },
	computed: {
		getCampaign () {
            return this.$router.currentRoute.query.hasOwnProperty('campaign') 
            ? this.$router.currentRoute.query.campaign
            : null;
		}
	}
}
</script>

<style scoped>
.form { display: flex; flex-wrap: wrap; }
.form input, .form select, .form textarea { width: 100%; box-sizing: border-box; border: 1px solid rgba(0,0,0,.2); border-radius: 2px; outline: none; padding: 7px 5px; }
.form--quick input, .form--quick select, .form--quick textarea { font-size: 2rem; padding: 14px 18px; border: 0; border-radius: 3px; box-sizing: border-box; }
.form__item { flex-basis: 100%; margin-top: 20px; display: flex; flex-direction: column; }
.form__item--stacked { flex-basis: calc(50% - 10px); }
.form__item--stacked + .form__item--stacked { margin-left: 20px;  }
.form__item label:not(.option):not(.segment__label) { color: #9c9c9c; font-weight: 400; font-size: 1.5rem; margin-bottom: 5px; }
.form .button { display: block; width: auto; margin: auto; margin-top: 10px; padding-left: 40px; padding-right: 40px; }
.form__checkbox { display: flex; align-items: center; padding: 5px 0; }
.form__checkbox input { flex: 0; margin-right: 10px; min-width: 20px; }

.loading { display: flex; align-items: center; } 
.loading__spinner { margin-left: 10px; animation: rotate 2s linear infinite; width: 20px; height: 20px; } 
.loading__path { stroke: #fff; stroke-linecap: round; animation: dash 1.5s ease-in-out infinite; } 
@keyframes rotate { 100% { transform: rotate(360deg); } } 
@keyframes dash { 0% { stroke-dasharray: 1, 150; stroke-dashoffset: 0; } 50% { stroke-dasharray: 90, 150; stroke-dashoffset: -35; } 100% { stroke-dasharray: 90, 150; stroke-dashoffset: -124; } }

</style>
