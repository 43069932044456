<template>
  <div>
    <div class="cover cover--small" :style="'background-image: url(' + require('@/assets/cover-vote.jpg') + ')'">
        <h1 class="cover__title">Team Aaron Gunn</h1>
    </div>
    <div class="section wrapper">
      <div class="section">
        <p class="text">Before becoming the next MP for North Island-Powell River, Aaron must first win the nomination contest to become the riding’s Conservative candidate.</p>
      </div>
      <div class="box">
         <template v-if="isRegistered">
            <h1 class="title">Successfully Registered</h1>
            <p class="text text--bold">Supporter successfully registered in campaign’s internal system. </p>
            <a class="button" :href="'/team/' + this.$route.params.volunter_pattern">Enter New Supporter</a>
            <div v-if="!isInRiding" class="check check--red"><span class="check__icon">X</span> <p>Attention: the postal code entered might not be in the riding North Island-Powell River. You can verify using the Elections Canada website, <a class="link" href="https://www.elections.ca/scripts/vis/FindED" target="_blank">here</a>.</p></div>
		   </template>
         <template v-else>
            <h1 class="title title--main">Welcome {{ leadershipObject.name }} and thank you for your continued hard work and dedication in helping Aaron!</h1>
            <p class="text">To date, you have signed up <span style="color: red; font-weight: bold">{{ leadershipObject.count || 0 }}</span> new pledged voters to the movement. This makes you the <span style="color: red; font-weight: bold">{{ leadershipObject.rank }}{{ getOrdinalSuffix(leadershipObject.rank) }}</span> most successful riding captain or regional coordinator in the province.</span></p>
            <p class="text">Add the names below of any <b>resident of North Island-Powell River</b> who have agreed to <b>purchase a Conservative Party Membership</b> and vote for Aaron.</p>
            <app-form 
               source="referral_registration_vote" 
               submit-text="Submit" 
               :quick-form="true" 
               destination="/team?registered" 
               :hidden-parameters="[{ name: 'team_referral_id', value: this.leadershipObject.id }, { name: 'team_referral_name', value: this.leadershipObject.name }]"
               :show-checkboxes="true">
            </app-form>
         </template>
      </div>
    </div>
</div>
</template>

<script>
import AppForm from '@/components/utils/AppForm.vue'
export default {
  components: {
    AppForm
  },
  created() {
      this.findMatchPattern().then(response => {
         this.leadershipObject = response;
      });
   },
  data () {
    return {
      leadershipObject: null
    }
  },
  computed: {
		isRegistered () {
		  return this.$router.currentRoute.query.hasOwnProperty('registered');
		},
 		isInRiding () {
		  return this.$router.currentRoute.query.hasOwnProperty('inRiding');
		} 
  },
  methods: {
      findMatchPattern() {
         var httpRequest = new XMLHttpRequest();
         return new Promise(resolve => {
            httpRequest.onreadystatechange = () => {
               if (httpRequest.readyState !== 4) return;
               if (httpRequest.status >= 200 && httpRequest.status < 300) {
                     resolve(JSON.parse(httpRequest.response));
               }
            }
            httpRequest.open("POST", "https://aarongunn.ca/webservices/team.php", true);
            var formData = new FormData();
            formData.append("pattern", this.$route.params.volunter_pattern);
            httpRequest.send(formData);
         });
      },
      getOrdinalSuffix(number) {
        if (number % 10 === 1 && number % 100 !== 11) {
          return 'st';
        } else if (number % 10 === 2 && number % 100 !== 12) {
          return 'nd';
        } else if (number % 10 === 3 && number % 100 !== 13) {
          return 'rd';
        } else {
          return 'th';
        }
      }
    }
}
</script>

<style scoped>
.steps { display: flex; width: 50%; margin: auto; padding: 20px 0 30px; }
.step { display: flex; flex-direction: column; align-items: center; margin: 0 10px; width: 0; overflow: visible; text-wrap: nowrap; z-index: 2; }
.line { flex-grow: 1; height: 2px; background-color: #DCDCDC; position: relative; margin-top: calc(25px / 2); z-index: 1; }
.step__circle { width: 25px; height: 25px; box-sizing: border-box; border-radius: 50%; background: white; border: 8px solid #DCDCDC; display: flex; align-items: center; justify-content: center; margin-bottom: 5px; }
.step--active .step__circle { border-color:#002B7A; }
.step__checkmark { display: none; font-size: 14px; color: white; }
.step--completed .step__circle { border: none; background-color: #002B7A; }
.step--completed .step__checkmark { display: block; }
.step__count { font-weight: bold; padding: 10px 0 5px; }

.check { display: flex; align-items: center; max-width: 700px; margin: auto; margin-top: 20px; margin-bottom: 50px; }
.check__icon  { min-width: 70px; height: 70px; margin-right: 20px; box-sizing: border-box; border-radius: 50%; font-size: 6rem; display: flex; align-items: center; justify-content: center; }
.check--green .check__icon { color: white; background: green; }
.check--red .check__icon { color: white; background: red; }

@media (max-width: 1100px) {

}

@media (max-width: 768px) {
  .steps { width: 100%; padding-bottom: 0; }
}

@media (max-width: 526px) {

}
</style>
